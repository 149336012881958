body{
  margin:0;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  font-size:16px;
  background-color:black;
  color:white;
}

::selection{ background-color:#ff9c9c; }

code{
  font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
      font-size:1.5rem;
}

p, a{
  font-size:.8rem!important;
      line-height:1.7!important;
}

.ab-toolbar.dark{
  background-color:black!important;
  color:white;
}

.ab-toolbar.dark ul li{
    white-space:nowrap;
  }

.HTML_Content{
  word-break:break-word;
}

.HTML_Content p{
        line-height:2!important;
  }

.mainLayout{
  min-height:100vh;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}

.mainLayout__content{
    width:100%;
    flex:1;
  }

label{
  color:black;
}

.ab-radio__label-text{
  color:white;
}

.ab-radio p{
  text-transform:capitalize;
}

.home-footer{
    background-size:cover;
    background-position:bottom;
    min-height:200px!important;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    width:100%;
    align-self:flex-end;
    background-image:linear-gradient( 45deg, #191919, black);
}

.home-footer .btt{
      display:flex;
      color:white;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      cursor:pointer;
    }

.home-footer .btt:hover .svg-wrap{
        background-color:rgba(255,82,82, .7);
      }

.home-footer .btt .svg-wrap{
        padding:12px;
        background-color:rgba(255,82,82, .4);
        border-radius:50%;
        min-width:45px;
        min-height:45px;
        display:flex;
        justify-content:center;
        align-items:center;
        transition:all .2s ease-out;
      }

.home-footer .btt svg{
        font-size:1em;
        position:relative;
      }

.home-footer .btt h1{ 
        font-weight:bold;  
        text-transform:capitalize;
      }

.home-footer p{
      color:#999999;
    }

.home-footer__paragraph{
      max-width:80%;
      text-align:center;
    }

.home-footer .ab-toolbar{
      background-color:transparent!important;
      color:white;
    }

.home-footer .ab-toolbar ul li{
        white-space:nowrap;
      }

.home-footer .ab-toolbar-inner{
      flex-direction:column;
    }

.footer{
  width:100%;
}

.footer .ab-toolbar{
      background-color:transparent!important;
      color:white;
    }

.footer .ab-toolbar ul li{
        white-space:nowrap;
      }

.footer p{
      color:#999;
    }

.footer .ab-toolbar.dark ul li a{
      color:#999;
      font-size:.75rem!important;
    }

.footer .ab-toolbar.dark ul li a:hover{
        color:white;
      }

.home-footer .ab-toolbar.dark ul li a,.footer .ab-toolbar.dark ul li a{
      color:#999;
      font-size:.75rem!important;
    }

.home-footer .ab-toolbar.dark ul li a:hover,.footer .ab-toolbar.dark ul li a:hover{
        color:white;
      }

.ab-headline h1{
      text-transform:capitalize;

    }

#about{
  background-color:black;
  padding:100px 0px;
  color:white;

}

#about .ab-headline{
    justify-content:flex-start;

  }

#about .ab-headline h1::before{
        width:100%;
        bottom:-20px;
      }

.img-bk-niko{
  position:relative;
  max-width:300px;
  max-height:300px;
}

.img-bk-niko::before{
    position:absolute;
    content:"";
    top:0px;
    left:-7.5px;
    width:calc(100% + 15px);
    height:calc(100% + 3px);
    background-color:rgba(25,25,25, .7);
    border-radius:50%;
    z-index:1;
  }

.img-niko{
  
  background-position:center;
  background-size:cover;
  max-width:100%;
  max-height:100%;
  height:300px;
  width:300px;
  border-radius:50%;
  position:relative;
  z-index:2;
}

.yoga{
  background-image:linear-gradient(-180deg, #000000, #fff);

  padding:100px 0px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background-attachment:fixed;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;
  color:white;
}

.yoga__text{
    background-color:rgba(0,0,0, .8);
    padding:50px;
  }

.book{
  padding:80px 0px;
  background-color:black;
}

.book__box{
    background-color:#C2C2C2;
    padding:30px;
    color:black;
    min-height:275px;
  }

.book__box h1{
      margin-top:0px;
    }

.book .ab-btn__wrapper{
    width:100%;
    margin:0px;
  }

.book .ab-btn__wrapper button{
      margin:0px;
      border-radius:0px!important;
          border:2px solid #ffffff;
          color:white!important;
          height:54px!important;
              margin:20px;
    }

.pt{
  padding:100px 0px;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;
  display:flex;
  flex-direction:column;
  align-items:center;
  color:white;
  position:relative;
}

.pt .overlay{
      width:100%;
      height:100%;
      top:0px;
      left:0px;
      position:absolute;
      z-index:1;
      background-color:rgba(0,0,0,.6);
  }

.pt .col, .pt .ab-headline{
    z-index:2;
  }

.pt__text{
    background-color:rgba(256,256,256, .8);
    padding:50px;
    color:black;
    z-index:2;
  }

.contact{
  padding:100px 0px;
  background-color:black;
}

.contact h1{
    color:white;
    margin-top:0px;
  }

.contact ul{
    padding:0px;
    margin:0px;
    margin-top:30px;
    display:flex;

  }

.contact ul li{
    list-style:none;
    padding-right:30px;
  }

.contact svg{
    color:white;
  }

.contact__header{
    color:white;
  }

.contact__header p{
      margin:0px;
    }

.contact__icon-wrap{
    display:flex;
    justify-content:center;
  }

.contact_page a{
    color:#999;
    text-decoration:none;
  }

.contact_page a:hover{
      color:white;
    }

.page-content__heading h1{
      text-transform:capitalize;
      margin-bottom:0px;
    }

.page-content__subHeading{
    margin-top:0px;
    margin-bottom:25px;
    color:#999;
  }

.page-content__subHeading p{
      margin-top:0px;
    }

.page-content__subHeading p::first-letter{
        text-transform:capitalize;
      }

.previewContent{
  color:white;
}

.previewContent p{
    line-height:1.7!important;
    color:white!important;
    font-weight:400!important;
  }

.ab-card__img-wrap{
  min-height:300px!important;
}

.ab-drawer__backdrop{
  background-color:rgba(256,256,256,.2)!important;
}

.ab-drawer__content{
  background-color:black!important;
  color:white;
  padding:30px;
  text-align:center;
}

.ab-drawer__content a{
    color:#999;
    text-decoration:none;
  }

.ab-drawer__content a:hover{
      color:white;
    }

.ab-drawer__content .ab-drawer__close::before, .ab-drawer__content .ab-drawer__close::after{
    background-color:white;
  }

.ab-drawer__content .ab-logo{
    color:white;
    font-size:1.5rem;
    margin-top:80px;
  }

.ab-drawer__content .ab-navigation-links{
    flex-direction:column;
    display:flex!important;
          margin:0px;
        padding:0px;
        list-style:none;
        align-items:center;
        height:100%;
  }

.ab-drawer__content .ab-navigation-links li{
      padding:15px 0px 0px;
          white-space:nowrap;
              box-sizing:border-box;
    display:block;
    width:100%;
    }

.ab-drawer__content .ab-navigation-links a{
      display:inline-flex;
          color:white;

              text-decoration:none;
    box-sizing:border-box;
    cursor:pointer;
    text-transform:uppercase;
    position:relative;
    z-index:1;

    }

.ab-drawer__content .ab-social{
    justify-content:center;
  }

.ab-drawer__content .ab-social ul{
      display:flex!important;
    }

.ab-toolbar.onlyMobil .ab-logo{
    color:white;
  }

.ab-toolbar.onlyMobil ul{
    display:flex;
  }

.footer .ab-toolbar ul,
.home-footer .ab-toolbar ul{
    display:flex!important;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
}

.footer .ab-toolbar ul li,.home-footer .ab-toolbar ul li{
      width:auto;
    }

.ewrg{
  z-index:2;
}

.ewrg > .col{
    color:white;
    justify-content:space-between;
    display:flex;


  }

.ewrg > .col > ul{
      display:flex;
      list-style:none;
      margin:0px;
  }

.ewrg > .col > ul li:not(:last-child){
        margin-right:20px;
      }

.projects{
  background-image:linear-gradient( 45deg, #191919, black);

}

.projects h1{
    position:relative;
    display:inline-flex;
    z-index:1;
  }

.projects h1:before{
      position:absolute;
      width:80%;
      height:40%;
      left:-10%;
      bottom:0px;
      content:"";
      background-color:rgba(255,82,82, .7);
      z-index:-1;
    }

.ab-section{
  display:flex;
  align-items:flex-end;
}

.ab-section__img{
    max-width:60%;
  }

.ab-section__img img{
      vertical-align:middle;
    }

.ab-section__content{
    max-width:40%;
    background-image:linear-gradient( 95deg, #191919, black);
    padding:40px;
    position:relative;
  }

.ab-section__content::before{
      position:absolute;
      content:"";
      width:100px;
      height:100%;
      left:-50px;
      top:0px;
      background-color:#191919;
      z-index:-1;
    }

.mainnav-wrap{
      position:absolute;
    bottom:0px;
    z-index:2;
    width:100%;
    padding:0px;
}

.mainnav ul{
    min-width:80%;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    background-color:rgba(0,0,0,.7);
    padding:20px;
    margin:0px;
  }

.mainnav li{
    list-style:none;
  }

.mainnav a{
    text-decoration:none;
    font-size:1.1rem!important;
    color:white;
  }

.nk-banner{
    min-height:65vh;
    background-attachment:fixed;
    background-size:cover;
    background-position:center;
    color:white;
    display:flex;
    flex-direction:column;
    
}

.nk-banner .overlay{
        width:100%;
        height:100%;
        position:absolute;
        z-index:1;
        background-image:linear-gradient(95deg, rgba(0,0,0,1), transparent);
    }

.nk-banner .ab-toolbar{
        background-color:transparent;
        z-index:2;
    }

.nk-banner ul li a, .nk-banner ul li p{
            color:white;
        }

.nk-banner ul li a{
            text-transform:capitalize;
        }

.nk-banner h1{
        font-size:4.5rem;
    }

.nk-banner__inner{
        flex:1;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        z-index:2;

    }

.nk-banner__inner ul{
            min-width:80%;
            display:flex;
            align-items:center;
            justify-content:space-evenly;
            background-color:rgba(0,0,0,.7);
            padding:20px;
        }

.nk-banner__inner li{
            list-style:none;
        }

.nk-banner__inner a{
            text-decoration:none;
            font-size:1.1rem!important;
            text-transform:capitalize;
        }

.nk-banner__logo-wrap{
        max-width:60%;
        display:flex;
        flex-direction:column;
    }

.nk-banner__logo-wrap img{
            max-width:400px;
        }

.nk-banner__heading{
        display:flex;
        align-items:center;
        font-size:4.5rem;
    }

.nk-banner__heading > div:first-child{
            background-color:rgba(214,60,60,.7);
            padding:0px 20px;
        }

body.--ios .nk-banner{
    background-attachment:scroll !important;
  }

@media screen and (max-width: 719px){
    .home-footer .ab-toolbar.dark ul,.footer .ab-toolbar.dark ul{
      margin:15px 0px;
    }
    .home-footer .ab-toolbar.dark ul li,.footer .ab-toolbar.dark ul li{
      padding:0px 10px;
    }
      .home-footer .ab-toolbar.dark ul li:first-child, .footer .ab-toolbar.dark ul li:first-child{
        padding-left:0px;
      }
      .home-footer .ab-toolbar.dark ul li:last-child, .footer .ab-toolbar.dark ul li:last-child{
        padding-right:0px;
      }
  .onlyBigScreen{
    display:none!important;
  }
  .mp-5{
    padding:30px 30px;
  }  
  .mp-2{
    padding:20px 20px;
  }  
  .ab-radio__options{
    flex-direction:column;

  }
  .ab-radio:not(.block) .ab-radio__option:not(:first-child){
    padding-left:0px!important;
  }
  .footer .ab-toolbar-inner{
    flex-direction:column;
  }

.ab-section{
    flex-direction:column
}
    .ab-section__img, .ab-section__content{
      max-width:100%;
    }

.nk-banner__logo-wrap img{
                padding:0px 25px 10px
        }

.nk-banner__logo-wrap{
            max-width:100%
    }
            .nk-banner__logo-wrap p{
                padding:0px 25px;
            }

.nk-banner__heading{
            font-size:3rem;
            padding:0px 25px 10px
    }
  }

@media screen and (min-width: 720px){
  .onlyMobil{
    display:none!important;
  }
}